<template>
  <ProductListLocked
    v-if="locked || !hasEquityJourney"
    lockedTitle="Unlock Equity"
    lockedSubtitle="Find equity funding in less than 5 minutes"
    icon1="equity"
    icon2="question"
    icon3="s-icon"
    filterGoalsByType="findInvestor"
    :lockedRouteName="'get-started'"
    :creditScore="false"
  />
  <div class="matches-wrapper" v-else>
    <div class="container-header">
      <div>
        <h1>Equity Funding</h1>
        <p>The Equity team will be in touch within 3 business days</p>
      </div>
      <div>
        <FormOverlay v-if="isPageOverlayOpen">
          <EnquiryForm @refetch-products="refetchProducts" />
        </FormOverlay>
        <div class="button-container">
          <button class="white-btn button" @click="openOverlayForm('findInvestor')">View and edit questions <em class="material-icons">edit_note</em></button>
        </div>
      </div>
    </div>

    <EquityUnlocked />
    <div class="bold">Thank you for signing up to Swoop and completing the information request!</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProductListLocked from '@product/components/products/ProductListLocked'
import ProductCategoryList from '@product/components/products/ProductCategoryList'
import EquityUnlocked from '@product/features/products/equity/equityUnlocked'
import EnquiryForm from '@product/features/goals/components/EnquiryForm'
import FormOverlay from '@/components/FormOverlay'
import { pageOverlayGetters, pageOverlayRoutine } from '@/store/modules/pageOverlay/routines'
import { companyGetters } from '@/store/modules/company/routines'
import { currentFormNameRoutine } from '@/store/modules/currentFormDetails/routines'
import { userRoutine, authGetters } from '@/store/modules/auth/routines'
import { amplitudeEvents } from '@/utils/constants'

export default {
  components: {
    ProductCategoryList,
    ProductListLocked,
    EquityUnlocked,
    EnquiryForm,
    FormOverlay
  },
  props: {
    locked: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions({
      setPageOverlay: pageOverlayRoutine.TRIGGER,
      setCurrentFormName: currentFormNameRoutine.TRIGGER,
      requestUser: userRoutine.TRIGGER
    }),
    openOverlayForm() {
      this.$ma.trackEvent({ eventType: amplitudeEvents.Matches.VIEW_AND_EDIT_EQUITY_FORM })
      this.setCurrentFormName('findInvestor')
      this.setPageOverlay(!this.isPageOverlayOpen)
    },
    refetchProducts(value) {
      this.setCurrentFormName('findInvestor')
      this.$emit('refetch-products', value)
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      isPageOverlayOpen: pageOverlayGetters.IS_PAGE_OVERLAY_OPEN,
      user: authGetters.USER
    }),
    hasEquityJourney() {
      return this.user?.goalsCompleted?.some(form => form.type === 'Equity')
    }
  },
  created() {
    this.requestUser()
  },
  beforeMount() {
    if (!this.$config.whitelabel.journey.equityEnabled) {
      this.$router.push({ name: 'dashboard' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.container-header {
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: $breakpoint-sm-max) {
    flex-direction: column;
  }
}
/deep/.bold {
  margin: 40px 0;
}
.steps {
  list-style: none;
  padding: 0;
}

/deep/ .button-container {
  text-align: right;
  margin-bottom: 30px;
  .material-icons {
    vertical-align: middle;
  }
}

@media only screen and (max-width: 770px) {
  .matches-wrapper {
    padding: 0px 0px;
  }
  .step {
    text-align: left;
  }
}
</style>
